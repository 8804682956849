import {uiActions} from "@store/ui/slice";
import {userActions} from "./slice.js";
import axiosInstance from "@helper/axios";
import {store as storeHistory, destroy as deleteHistory} from "@store/History/actions";
import {setSnackbar2} from "@store/ui/actions";
import {authActions} from "@store/Auth/slice";

const basePath = '/profile/users';

export const validationMessages = (formType) => {
    return dispatch => {

        axiosInstance.get(`${basePath}/validationMessages`, {
            params: {'form_type': formType}
        })
            .then(response => {
                dispatch(uiActions.setErrorMessages({
                    messages: response.messages
                }))
            })
            .catch(error => {
                console.log(error);
            })
    }
}

//Todo: To be deleted because calling from SSR
export const edit = () => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/edit`)
            .then(response => {
                dispatch(userActions.setEditData(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const update = (formData) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/update`, formData)
            .then(response => {
                dispatch(edit());
                dispatch(authActions.updateUser(response.data))
                dispatch(setSnackbar2(response.message));
                dispatch(uiActions.toggleSpinner({spinner: false}));

            })
            .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

//This function is created so that loading spinner can be skipped otherwise "update" API was enough
export const updateContact = (formData, mobileUpdateDialogCloseHandler, authUser) => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/update`, formData)
            .then(response => {
                mobileUpdateDialogCloseHandler();
                dispatch(uiActions.toggleSpinner({spinner: false}));
                dispatch(authActions.updateUser(response.data))
                if (authUser.iso_code !== formData.iso_code) {
                    location.reload();
                }
            })
    .catch(([error, status]) => {
                console.log(error.response);
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const myProducts = () => {
    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.get(`${basePath}/myProducts`, {
            params: {
                conversion: '1280x720'
            }
        })
            .then(response => {
                dispatch(userActions.setMyProducts(response));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                console.log(error.response);
                if (status === 404) {
                    dispatch(userActions.setMyProducts());
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}

export const markProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as complete'))
        dispatch(userActions.toggleMarkAsComplete({productId, status: true}));
        dispatch(storeHistory(productId));
    }
}

export const unmarkProductComplete = (productId) => {
    return dispatch => {
        dispatch(setSnackbar2('Class marked as in progress'))
        dispatch(userActions.toggleMarkAsComplete({productId, status: false}));
        dispatch(deleteHistory(productId));
    }
}

export const storeSubscriptionEmail = (formData) => {
    return dispatch => {
        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/storeSubscriptionEmail`, formData)
            .then(response => {
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
    }
}
