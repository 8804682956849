import axiosInstance from "@helper/axios";
import {uiActions} from "@store/ui/slice";
import {CHECKOUT, ORDER} from "@helper/constants/routes";
import {passRouteParams} from "@helper/functions";
import {edit} from "@store/Operator/User/actions";

const basePath = '/payments';

//STRIPE
export const checkoutWithStripe = (formData) => {
    return dispatch => {

        dispatch(uiActions.togglePurchaseSpinner({spinner: true}));

        axiosInstance.post(CHECKOUT.store, formData)
            .then(response => {
                console.log('Stripe: ', response);
                window.location = response.subscription.url;

                //dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
    }
}

export const payWithStripe = (formData, orderId) => {

    return dispatch => {

        console.log('Stripe Action:', orderId);

        dispatch(uiActions.togglePurchaseSpinner({spinner: true}));

        axiosInstance.post(passRouteParams(ORDER.store, {id: orderId}), formData)
            .then(response => {

                window.location = response.subscription.url;

                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.togglePurchaseSpinner({spinner: false}));
            })
    }
}

//Updating Payment Status
export const updatePaymentStatus = (sessionId, router) => {

    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        console.log('hostname: ', window.location.hostname);
        console.log('AXIOS: ', axiosInstance);

        axiosInstance.post(`${basePath}/updateStripePayment`, {
            'session_id': sessionId
        })
            .then(response => {
                console.log(response);
                if (response.status === 'success') {
                    router.push('/?STATUS=PAYMENT_SUCCESSFUL')
                }
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })

    }
}

//Cancel Subscription
export const cancelStripeSubscription = (subscriptionId, userId) => {

    return dispatch => {

        dispatch(uiActions.toggleSpinner({spinner: true}));

        axiosInstance.post(`${basePath}/${subscriptionId}/cancelStripeSubscription`)
            .then(response => {
                console.log(response);
                dispatch(uiActions.setSuccessApiResponse({message: response.message}))
                dispatch(uiActions.toggleSpinner({spinner: false}));
                dispatch(edit(userId));
            })
            .catch(([error, status]) => {
                dispatch(uiActions.setErrorApiResponse(error));
                console.log(error);
                dispatch(uiActions.toggleSpinner({spinner: false}));
            })

    }
}
