//React, React Router, Formik
import React, {useState} from 'react';
import {PRODUCT} from "@helper/constants/routes";
import {passRouteParams} from "@helper/functions";
import Link from "next/link";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Tooltip, Typography} from "@material-ui/core";
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import IconButton from "@material-ui/core/IconButton";
import BookmarkRoundedIcon from '@material-ui/icons/BookmarkRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

//Redux
import {useDispatch, useSelector} from "react-redux";
import CustomImage from "@util/CustomImage/CustomImage";

//Assets

const useStyles = makeStyles(theme => ({
    cardLink: {
        "&:hover": {
            textDecoration: 'none'
        }
    },
    card: {
        ...theme.typography.lmsCard,
    },
    thumbnailWrapper: {
        position: 'relative',
        "& img": {
            width: '100%',
            height: 'auto',
            aspectRatio: '16/9',
            borderRadius: '8px 8px 0 0'
        },
        "& .MuiSvgIcon-root": {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            "&:nth-child(2)": {
                width: 55,
                height: 55,
                color: theme.palette.primary.main
            },
            "&:nth-child(3)": {
                width: 32,
                height: 32,
                color: '#FFF'
            },
        }
    },
    detailWrapper: {
        padding: theme.spacing(0, 2, 2)
    },
    chaptersCount: {
        color: theme.palette.grey.limed_spruce,
        fontSize: '13px',
        lineHeight: '24px',
        "&::selection": {
            backgroundColor: theme.palette.secondary.main
        },
    },
    duration: {
        color: theme.palette.grey.limed_spruce,
        fontSize: '13px',
        lineHeight: '24px',
        "&::selection": {
            backgroundColor: theme.palette.secondary.main
        },
    },
    title: {
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '1.33',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
        height: '60px',
        display: '-webkit-box',
        boxOrient: 'vertical',
        lineClamp: 3,
        overflow: 'hidden',
        "&::selection": {
            backgroundColor: theme.palette.secondary.main
        },
    },
    footer: {
        paddingTop: theme.spacing(2),
    },
    authorNameWrapper: {
        width: 'unset',
        flexGrow: 1,
        overflow: 'hidden',
        marginRight: theme.spacing(2),
        "& .MuiTypography-root": {
            color: theme.palette.grey.limed_spruce,
            "&::selection": {
                backgroundColor: theme.palette.secondary.main
            },
            "&:hover": {
                color: theme.palette.grey.limed_spruce,
            }
        }
    },
    bookmarkIconButtonWrapper: {
        height: 24,
        alignSelf: 'center',
        "& .MuiIconButton-root": {
            all: 'unset',
            "& .MuiSvgIcon-root": {
                fill: theme.palette.grey.limed_spruce,
            }
        }
    },
    /*bookmarkIconButton: {
        all: 'unset'
    },
    bookmarkIcon: {
        fill: theme.palette.grey.limed_spruce,
    }*/
}));

const CourseCard = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const assetPath = useSelector(state => state.siteDetail.assetPath);

    const {product_id, title, slug, instructors, thumbnail, duration, chapters_count, is_bookmarked} = props;
    const thumbnailUrl = thumbnail?.url || `${assetPath}upload-image-placeholder.png`;

    const [hoveredThumbnail, setHoveredThumbnail] = useState(false);

    const {addBookmark, removeBookmark} = props;

    const handleAddBookmark = (event) => {
        event.preventDefault();
        if (props.authModalOpenhandler) {
            props.authModalOpenhandler('signIn');
        } else {
            dispatch(addBookmark(product_id, props.slice));
        }
    }

    const handleRemoveBookmark = (event) => {
        event.preventDefault();
        if (props.authModalOpenhandler) {
            props.authModalOpenhandler('signIn');
        } else {
            dispatch(removeBookmark(product_id, props.slice));
        }
    }

    const handleMouseOver = (state) => {
        setHoveredThumbnail(state);
    }

    return (
        <Link href={{
            pathname: PRODUCT.view,
            query: {slug}
        }} passHref>
            <a className={classes.cardLink}>
                <Grid container wrap="nowrap" direction="column" className={classes.card}>
                    <Grid
                        item
                        className={classes.thumbnailWrapper}
                        onMouseEnter={() => handleMouseOver(true)}
                        onMouseLeave={() => handleMouseOver(false)}
                    >
                        <CustomImage
                            loaded={!!assetPath}
                            src={thumbnailUrl}
                            alt="Thumbnail"
                            width={1280}
                            height={720}
                        />
                        {
                            hoveredThumbnail &&
                            <>
                                <FiberManualRecordRoundedIcon/>
                                <PlayArrowRoundedIcon/>
                            </>

                        }
                    </Grid>
                    <Grid item container direction="column" className={classes.detailWrapper}>
                        <Grid item container justifyContent="space-between">
                            <Grid item className={classes.chaptersCount}>
                                {chapters_count} chapters
                            </Grid>
                            <Grid item className={classes.duration}>
                                {duration}
                            </Grid>
                        </Grid>
                        <Grid item className={classes.title}>
                            {title}
                        </Grid>
                        <Grid item container wrap="nowrap" justifyContent="space-between" className={classes.footer}>
                            <Grid item className={classes.authorNameWrapper}>
                                <Typography noWrap>
                                    {
                                        instructors && instructors.map((instructor, index, instructors) => (
                                            `${instructor.full_name}${index+1 === instructors.length ? '' : ', '}`
                                        ))
                                    }
                                </Typography>
                            </Grid>
                            <Grid item className={classes.bookmarkIconButtonWrapper}>
                                {
                                    is_bookmarked ?
                                        <Tooltip title="Remove Class">
                                            <IconButton type="button" onClick={handleRemoveBookmark}>
                                                <BookmarkRoundedIcon />
                                            </IconButton>
                                        </Tooltip> :
                                        <Tooltip title="Save Class">
                                            <IconButton type="button" onClick={(event) => handleAddBookmark(event)}>
                                                <TurnedInNotIcon />
                                            </IconButton>
                                        </Tooltip>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </a>
        </Link>
    );
};

export default CourseCard;
