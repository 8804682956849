//React, React Router, Formik
import React, {useState} from 'react';
import AuthModal from "@ui/AuthModal/AuthModal";
import Link from "next/link";
import {CHECKOUT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
    },
    getStartedButton: {
        height: 40,
        padding: theme.spacing(0, 3),
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.main,
        maxHeight: 200,
    }
}));

const GetStartedButton2 = () => {

    const classes = useStyles();

    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('signIn');

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const {isZSDomain} = useSelector(state => state.ui);

    const changeModalMode = (mode) => {
        setModalMode(mode);
    }

    const handleAuthModalOpen = (mode) => {
        setAuthModalOpen(true);
        changeModalMode(mode);
    };

    const handleAuthModalClose = () => {
        setAuthModalOpen(false);
    };

    return (
        <div className={classes.root}>
            {
                !isAuthenticated && !authModalOpen &&
                <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    className={classes.getStartedButton}
                    onClick={() => handleAuthModalOpen('signUp')}
                >
                    {isZSDomain ? 'Buy Now' : 'Start Your Membership'}
                </Button>
            }
            {
                isAuthenticated &&
                <Link href={CHECKOUT.show}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        className={classes.getStartedButton}
                        component="a"
                    >
                        {isZSDomain ? 'Buy Now' : 'Start Your Membership'}
                    </Button>
                </Link>
            }

            {/*Auth Modal*/}
            {
                !isAuthenticated && authModalOpen &&
                <AuthModal
                    open={authModalOpen}
                    closeHandler={handleAuthModalClose}
                    modalMode={modalMode}
                    modalModeHandler={changeModalMode}
                />
            }

        </div>
    );
};

export default GetStartedButton2;
