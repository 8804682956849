//React, React Router, Formik
import React, {useState} from 'react';
import AuthModal from "@ui/AuthModal/AuthModal";
import Link from "next/link";
import {CHECKOUT} from "@helper/constants/routes";

//Material UI
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";

//Redux
import {useSelector} from "react-redux";

//Assets

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        zIndex: 10000,
        top: 0,
        right: 24,
        visibility: 'hidden',
        opacity: 0,
        transition: 'all .2s ease-in-out',
        maxHeight: 0,
    },
    show: {
        top: 32,
        visibility: 'visible',
        opacity: 1,
    },
    getStartedButton: {
        height: 52,
        padding: theme.spacing(0, 7.5),
        border: `1px solid ${theme.palette.secondary.main}`,
        color: theme.palette.primary.main,
        maxHeight: 200,
    }
}));

const GetStartedButton = ({targetElementRef}) => {

    const classes = useStyles();

    const [authModalOpen, setAuthModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('signIn');
    const [scrollPast, setScrollPast] = useState(false);

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const {isZSDomain} = useSelector(state => state.ui);

    const changeModalMode = (mode) => {
        setModalMode(mode);
    }

    const handleAuthModalOpen = (mode) => {
        setAuthModalOpen(true);
        changeModalMode(mode);
    };

    const handleAuthModalClose = () => {
        setAuthModalOpen(false);
    };

    if (typeof window !== 'undefined') {
        window.addEventListener("scroll", function () {
            if (window.scrollY > (targetElementRef.current?.offsetTop + targetElementRef.current?.offsetHeight)) {
                setScrollPast(true);
            } else {
                setScrollPast(false);
            }
        });
    }

    return (
        <div className={`${classes.root} ${scrollPast ? classes.show : ''}`}>
            {
                !isAuthenticated && !authModalOpen &&
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    className={classes.getStartedButton}
                    onClick={() => handleAuthModalOpen('signUp')}
                >
                    {isZSDomain ? 'Buy Now' : 'Start Your Membership'}
                </Button>
            }
            {
                isAuthenticated &&
                <Link href={CHECKOUT.show}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        className={classes.getStartedButton}
                        component="a"
                    >
                        {isZSDomain ? 'Buy Now' : 'Start Your Membership'}
                    </Button>
                </Link>

            }

            {/*Auth Modal*/}
            {
                !isAuthenticated && authModalOpen &&
                <AuthModal
                    open={authModalOpen}
                    closeHandler={handleAuthModalClose}
                    modalMode={modalMode}
                    modalModeHandler={changeModalMode}
                />
            }

        </div>
    );
};

export default GetStartedButton;
